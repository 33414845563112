.socialLoginPage{
    $black: #333333;
    $white: #fefefe;
    $red: red;
    // Change these up with something at
    // http://paletton.com/
    // or http://uigradients.com/
    $color-1: #C6F500;
    $color-2: #E7003E;
    $bg-color-1: #647691;
    $bg-color-2: rgb(164, 206, 219);
    // Easing for cool animations
    $easing: cubic-bezier(0,.5,.65,1.47);



    position: fixed !important;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;

    .social-share {
    text-align: center; 
    width: 100%;
    }

    .social {
    text-decoration: none;
    color: $black;
    background: none;
    border: 0;
    font-size: 3rem;
    display: inline-block;
    width: 4.5rem;
    height: 4.5rem;
    line-height: 4.5rem;
    position: relative;
    transform: scale(2);
    + .social {
        margin-left: 1rem;
    }
    .fa {
        position: relative;
        z-index: 20;
        transition: all .2s $easing;
        cursor: pointer;
    }
    .screen-reader-text {
        z-index: 10;
        display: block;
        text-indent: -999vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $black;
        border-radius: 50%;
        width: 0;
        height: 0;
        transition: all .2s $easing;
        visibility: visible;
        cursor: pointer;
    }
    &:hover {
        .fa {
        color: $white;
        }
        .screen-reader-text {
        width: 100%;
        height: 100%;
        box-shadow: 0 0 5px 1px rgba(black, .15);
        }
    }
    &.google   .screen-reader-text {background-color: #dd4b39;}
    }

    main {
    display:flex;
    align-items:center;
    height: 100vh;
    }

    .screen-reader-text {
        // visibility: hidden;
    }

    *,
    *::before,
    *::after {
    box-sizing: border-box;
    }
    main {
    min-height: 100%;
    font-family: 'Roboto', sans-serif;
    }
    main p {
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
    }
    main {
    color: $black;
    background: linear-gradient(25deg, $bg-color-1, $bg-color-2, $bg-color-1);
    background-size: 800% 800%;
    -webkit-animation: colors 15s ease infinite;
    -moz-animation: colors 15s ease infinite;
    animation: colors 15s ease infinite;
    }

      @-webkit-keyframes colors {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
      }
      @-moz-keyframes colors {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
      }
      @keyframes colors { 
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
      }

    input {
    transition: border-color .15s ease-in-out;
    margin: 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba($black, .15);
    padding: .15rem;
    color: rgba($black, .95);
    &:hover {
        color: rgba($black, 1);
        border-color: rgba($black, .75);
    }
    &:focus {
        outline: none;
        border-bottom: 3px solid $black;
        margin-bottom: -2px;
    }
    }
    .material-button, .material-delete {
    transition: all .15s ease-in-out;
    background: $color-2;
    border: none;
    font-size: .9rem;
    line-height: 2rem;
    border-radius: 3px;
    color: $white;
    box-shadow: 1px 1px 3px 0 rgba($black, .15);
    text-transform: uppercase;
    &:hover {
        background: lighten($color-2, 20);
        box-shadow: 1px 3px 3px 0 rgba($black, .15);
    }
    &:active {
        top:-.7rem;
        outline: 0;
        box-shadow: none;
    }
    }
    .material-delete {
    font-size: 1rem;
    line-height: 1rem;
    height: 2rem;
    width: 2rem;
    top: 0;
    right: -1.5rem;
    transform: rotate(45deg);
    background: none;
    color: rgba($black, .15);
    box-shadow: none;
    &:hover {
        background: rgba($black, .075);
        color: $white;
        box-shadow: none;
    }
    &:active {
        color: $red;
        background: transparent;
        top: 0;
    }
    }
    .material-modal-button {
    cursor: pointer;
    background: transparent;
    border: 0;
    color: darken($color-2, 10);
    &:hover {
        color: $color-2;
    }
    }
    .preventModal{
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba($black, .8);
        transition: visibility 0.2s linear, opacity 0.2s linear;
        visibility: visible;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index:21;
    }
    .hide{
        visibility: hidden;
        opacity: 0;
    }
}