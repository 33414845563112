

.mainMenu{
	@media screen and (min-width: 992px) and (min-height: 1200px) {
		// margin-top: 40vh;
		// transform: translateY(-50%);
	}
	transition: margin-top .3s linear, transform .3s linear;
	
	&.toomany {
		margin-top: 0;
		// transform: translateY(0);
	}
	
	
	max-width: 1350px;
	margin: 0 auto;
	
	min-height: calc(100vh - 170px);
	padding-bottom: 50px;
	
	
	.holder {
		width:200px;
		height:200px;
		display: block;
		margin: 25px;
		
		transition: width .15s linear, height .15s linear, margin .15s linear, transform 1s ease 0s;
		
		transform: perspective(1500px) rotateY(15deg);

		&:hover {
			transform: perspective(3000px) rotateY(5deg);
		}

		&>div {
			height: 100%;
		}

		label {
			font-size: 20px;
			font-weight: 400;
		}
		
		.appButton{
			
			overflow: hidden;
			border-radius: 23%;
			box-shadow: 0 5px 28px 5px rgba(0,0,0,.1);
			transition: box-shadow .2s ease, transform .2s ease, border-radius .2s ease;
			transform: translateY(0)!important;
			&:hover{
				// box-shadow: 0px 0px 10px -6px rgba(0,0,0,.1);
				// transform: translateY(2px)!important;
				
				label {
					transform: translateY(0);
				}
			}
			
			
			height: 100%;
			cursor: pointer;
			
			.appButtonImage{
				width: 100%;
				height: 100%;
				background-size: contain;
				background-position: center;
				box-shadow: 4px 4px 16px -2px inset #ffffff;
				position: relative;
			}
		}
		
	}
	
	@media screen and (max-width: 560px) {
		.holder {
			width:120px;
			height:120px;
			margin: 15px;
		}
	}
	
}
