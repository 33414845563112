// Here you can add other styles

body {
  background-color: #fff;
}

.app-header {
  background-color: #323232;
}

.navbar-nav .nav-link {
  color: #fff;
  
  &:hover, &:focus {
    color: rgb(216, 216, 216);
  }

  label {
    cursor: pointer;  
  }
}

.appFooter {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 60px;
}

.vertContain {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
.show-login {
  transition: all 2s ease;
  .app-header,
  .app-footer {
    transition: all 2s ease;
  }
  .app-header {
    top: -100px;
  }
  .app-footer {
    bottom: -100px;
  }
  .socialLoginPage {
    transition: all 2s ease;
  }
  &.fade-login {
    .app-header,
    .app-footer {
      opacity: 1;
    }
    .app-header {
      top: 0;
    }
    .app-footer {
      bottom: 0;
    }
    .socialLoginPage {
      opacity: 0;
    }
  }
}

.main .container-fluid {
  padding-top: 100px;
  padding-bottom: 60px;
}

.userslistimage {
  width: 50px;
  margin-right: 10px;
}

.usersTable th {
  border-top: none;
}

.userslistapp {
  width: 50px;
  border-radius: 10px;
  margin: 3px;
}

.cardHeader {
  line-height: 35px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

button {
  outline: 0 !important;
}

.myBadge {
  line-height: 20px;
}

.appPageIcon img {
  border-radius: 20%;
  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}

.appUsers {
  margin-bottom: 20px;
  height: 60px;
  .sk-three-bounce {
    margin-top: 20px;
  }
}

.addUserBtn {
  display: block;
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 50%;
  line-height: 50px;
  outline: 0;
  border: none;
  background-color: rgb(55, 55, 55);
  color: white;
  cursor: pointer;
  transition: all 0.15s linear;
  &:hover {
    color: black;
    background-color: white;
  }
}

pre {
  white-space: pre-wrap;
}

.slim {
  margin: auto;
}

.file-dropping-view {
  border: 5px dashed gray;
  background: rgba(128, 128, 128, 0.4);
  left: 15px;
  right: 15px;
  bottom: 25px;
  top: 0;
  position: absolute;
  z-index: 100;
  strong {
    font-size: 50px;
    color: white;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
  }
}

.display-none {
  display: none;
}

.slim-uploader {
  &.slim-uploader-error {
    border: 1px solid #f86c6b;
  }
}

/* Example Styles for React Tags*/

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 100%;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  @extend .form-control;
  @extend .is-valid;
  margin: 4px 0;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  // border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}
.is-valid {
  background-image: none !important;
}

.fa.fa-remove.p.hide {
  transform: translateX(-25px);
  opacity: 0;
  margin-right: -10px;
}

.fa.fa-remove.p {
  transform: translateX(0);
  opacity: 1;
  transition: all 0.15s ease;
  margin-right: 5px;
}

.fa.fa-clipboard.p {
  opacity: 1;
  transition: all 0.2s linear;
  margin-left: 5px;
}

.fa.fa-clipboard.p.hide {
  opacity: 0;
}

.bg-danger.p {
  background-color: #ffeaea !important;
  color: #2f353a;
}

.apps-preview-tab-content-image {
  width: 120px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  float: left;
  border: 1px solid rgba(1, 1, 1, 0.1);
  margin: 5px 2px 5px 2px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 4px -2px grey;
  box-shadow: 0 0 4px -2px grey;
}
.apps-preview-tab-image {
  width: 40px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  float: left;
}
.app-store-icon {
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
}

.screen-shot-picture {
  width: 100%;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.table-responsive {
  overflow: visible !important;
}

.configuration-table {
  [role="combobox"] {
    [role="listbox"] {
      [role="option"] {
        padding: 10px 5px !important;
      }
    }
  }
}

.assets-table {
  th,
  td {
    vertical-align: middle;
  }
}

.resource-image {
  &:hover {
    -webkit-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
  }
}

.sketch-picker {
  margin-bottom: 70px;
}

.sk-three-bounce {
  margin: 40px auto;
  text-align: center;
}